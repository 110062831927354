/** @format */

import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  // eslint-disable-next-line
  Redirect,
} from 'react-router-dom';
import {Helmet} from 'react-helmet';
// Page imports
import Home from './pages/home';
import ItemPage from './pages/item';
import Checkout from './pages/checkout';
import CheckoutMobile from './pages/checkoutMobile';
import Blog from './pages/blog';
import Article from './pages/blog/article';
import Features from './pages/features';
import ProcessPg from './pages/process';
import Shipping from './pages/shipping';
import Drip404 from './pages/404/';
import AccountPage from './pages/userAccount';
import AccountPageMobile from './pages/accountMobile';
import FaqPg from './pages/faq';
import AboutPg from './pages/about';
import AboutUs from './pages/aboutUs';
// import FaqPgMobile from './components/mobile/';
import Tos from './pages/tos';
import TosMobile from './components/mobile/tos';
import Betatest from './pages/betatest';
import Privacy from './pages/privacypolicy';
import PrivacyMobile from './components/mobile/privacy';
import MinisPage from './pages/mini';
import BulkPage from './pages/bulkBox';
import PodPage from './pages/pod';
import CardsPromo from './pages/cardspromo';
import PaypalCheckoutResults from './pages/paypalCheckoutResults';
import PaypalCheckoutResultsMobile from './components/mobile/paypalCheckoutResults';
import ShopPage from './pages/shop';
import Subscription from './pages/subscription';
import SubscriptionMobile from './pages/checkoutsubscriptionMobile';
import Madness from './pages/madness';
import PremiumPg from './pages/premium';
import Vending from './pages/vending';
import Vote from './pages/vote';
import VoteMobile from './components/mobile/vote';
// Component imports
import Header from './components/header';
import HeaderMobile from './components/mobile/header';
import Footer from './components/footer';
import FooterMobile from './components/mobile/footer';
import LoginModal from './components/login';
import LoginMobileModal from './components/mobile/login';
// import { PlaNoticeModal } from './components/notices';
import {useSnackbar} from 'notistack';
import GoogleAnalytics from './GoogleAnalytics';
import Tutorials from './pages/tutorials';
import HolidayClosureBanner from './components/holidayBanner';
// import Notification from './components/noticeBanner';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import {ShoppingCart} from 'react-feather';

function Snack(props) {
  const {cartUtils, cartData, isMobile} = props;
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const history = useHistory();
  useEffect(() => {
    if (cartUtils?.message.msg) {
      let {msg, type} = cartUtils.message;
      let opts = {variant: type};
      if (msg === 'Item added to cart.' && isMobile) {
        msg = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '80vw',
            }}>
            <div>Item added to cart.</div>
            <Button
              style={{marginLeft: 'auto'}}
              onClick={() => {
                history.push('/cart');
                closeSnackbar();
              }}>
              <Badge badgeContent={cartData.length} color="secondary">
                <ShoppingCart color="#fff" />
              </Badge>
            </Button>
          </div>
        );
      }
      enqueueSnackbar(msg, opts);
      cartUtils.clearMsg();
    }
    // eslint-disable-next-line
  }, [cartData]);

  return <></>;
}

function Routes(props) {
  const {userData, cartUtils, cartData, isMobile, updateMemberShip} = props;
  const [holliday, setHolliday] = useState();

  useEffect(() => {
    fetch(`/api/holliday`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(setHolliday);
  }, []);

  const structuredData = () => {
    let data = {
      "@context": "https://schema.org",
      "@type": "OnlineStore",
      "name": "PrintBed",
      "url": "https://www.printbed.com/",
      "logo": "https://cdn.sunsh1n3.com/Images/logos/pb-half-2023.svg",
      "contactPoint": [{
        "@type": "ContactPoint",
        "contactType": "Customer Service",
        "email": "support@printbed.com",
        "telephone": "+1-833-445-2600"
      }],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "14920 23 Mile Rd",
        "addressLocality": "Shelby Township",
        "addressRegion": "MI",
        "postalCode": "48315",
        "addressCountry": "US"
      },
      "sameAs": [
        "https://www.facebook.com/printbed/",
        "https://www.twitter.com/printbed",
        "https://www.instagram.com/print_bed",
        "https://www.youtube.com/channel/UCBsoiOZE9kGJU6W5E6SxBDA",
        "https://discord.gg/XqsxbkqPQM"
      ]
    }

    return JSON.stringify(data);
  }
  return (
    <Router>
      {isMobile ? (
        <HeaderMobile
          userData={userData}
          cartData={cartData}
          cartUtils={cartUtils}
        />
      ) : (
        <Header userData={userData} cartData={cartData} cartUtils={cartUtils} />
      )}
      {holliday && navigator.userAgent !== 'ReactSnap' && (
        <HolidayClosureBanner isMobile={isMobile} holliday={holliday} />
      )}
      <Switch>
        <Route path={'/checkout/paypalResults'}>
          {isMobile ? (
            <PaypalCheckoutResultsMobile
              cartUtils={cartUtils}
              userData={userData}
            />
          ) : (
            <PaypalCheckoutResults cartUtils={cartUtils} userData={userData} />
          )}
        </Route>
        <Route path={'/checkout'}>
          <Helmet>
            <title>
              Checkout | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          {isMobile ? (
            <CheckoutMobile
              userData={userData}
              cartData={cartData}
              cartUtils={cartUtils}
            />
          ) : (
            <Checkout
              userData={userData}
              cartData={cartData}
              cartUtils={cartUtils}
            />
          )}
        </Route>

        <Route path={'/blog/:slug'}>
          <Article userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/blog'}>
        <Helmet>
            <title>
            3D Printing Tips | Using Tangle-Free Spools | PrintBed
            </title>
            <meta
              name="description"
              content="Learn the best 3D printing tips with PrintBed. Our 3D printing blog explains how to use tangle-free spools to bring your ideas to life with precision."
            />
          </Helmet>
          <Blog userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/madness'}>
          <Madness userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/features'}>
          <Features userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/shipping'}>
          <Helmet>
            <title>
              Shipping | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <Shipping userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/help'}>
          <Helmet>
            <title>
              Frequently Asked Questions | Product Information | PrintBed
            </title>
            <meta
              name="description"
              content="Find the answers to frequently asked questions about orders and product information at PrintBed. Learn about subscription, shipping, filaments, and more."
            />
          </Helmet>
          <FaqPg userData={userData} isMobile={isMobile} />
        </Route>
        {/* <Route path={'/about'}>
          <Helmet>
            <title>
              About Us | High-Quality 3D Printing Filament | PrintBed
            </title>
            <meta
              name="description"
              content="PrintBed supplies color-consistent 3D printing filament that never gets tangled. Made and sourced in the USA with renewable resources, learn more at PrintBed."
            />
          </Helmet>
          <AboutUs userData={userData} isMobile={isMobile} />
        </Route> */}
        <Route path={'/about'}>
          <Helmet>
            <title>
              About Us | High-Quality 3D Printing Filament | PrintBed
            </title>
            <meta
              name="description"
              content="PrintBed supplies color-consistent 3D printing filament that never gets tangled. Made and sourced in the USA with renewable resources, learn more at PrintBed."
            />
          </Helmet>
          <AboutPg userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/account/:tab'}>
          {isMobile ? (
            <AccountPageMobile userData={userData} />
          ) : (
            <AccountPage userData={userData} />
          )}
        </Route>
        <Route path={'/vote'}>
          <Helmet>
            <title>Vote | High-Quality 3D Printing Filament | PrintBed</title>
          </Helmet>
          {isMobile ? (
            <VoteMobile userData={userData} />
          ) : (
            <Vote userData={userData} />
          )}
        </Route>
        <Route path={'/tos'}>
          <Helmet>
            <title>
              Terms of Service | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          {isMobile ? (
            <TosMobile userData={userData} />
          ) : (
            <Tos userData={userData} />
          )}
        </Route>
        <Route path={'/premium'}>
          <Helmet>
            <title>
              Filament Subscription Box | Monthly Premium Spool | PrintBed
            </title>
            <meta
              name="description"
              content="Get the filament subscription box every month when you subscribe to PrintBed. Premium members get a unique PLA filament to inspire new ideas. Subscribe now."
            />
          </Helmet>
          <PremiumPg userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/betatestagreement'}>
          <Betatest userData={userData} />
        </Route>
        <Route path={'/tutorials/:id'}>
          <Helmet>
            <title>
              Tutorials | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <Tutorials userData={userData} />
        </Route>
        <Route path={'/tutorials'}>
          <Helmet>
            <title>
              Tutorials | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <Tutorials userData={userData} />
        </Route>
        <Route path={'/subscription'}>
          <Helmet>
            <title>
              Subscription | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          {isMobile ? (
            <SubscriptionMobile
              userData={userData}
              cartData={cartData}
              cartUtils={cartUtils}
              updateMemberShip={updateMemberShip}
              isMobile={isMobile}
            />
          ) : (
            <Subscription
              userData={userData}
              cartData={cartData}
              cartUtils={cartUtils}
              updateMemberShip={updateMemberShip}
              isMobile={isMobile}
            />
          )}
        </Route>
        <Route path={'/cardsV2'}>
          <CardsPromo isMobile={isMobile} userData={userData} />
        </Route>
        <Route path={'/privacy'}>
          <Helmet>
            <title>
              Privacy Policy | Quality Tangle-Free Spools | PrintBed
            </title>
            <meta
              name="description"
              content="Get tangle-free 3D printing rolls that help prevent issues during printing. Buy consistent 3D printer filament from PrintBed and bring your design to reality."
            />
          </Helmet>
          {isMobile ? (
            <PrivacyMobile userData={userData} />
          ) : (
            <Privacy isMobile={isMobile} userData={userData} />
          )}
        </Route>
        <Route path={'/process'}>
          <Helmet>
            <title>The Process | Quality Tangle-Free Spools | PrintBed</title>
            <meta
              name="description"
              content="Get tangle-free 3D printing rolls that help prevent issues during printing. Buy consistent 3D printer filament from PrintBed and bring your design to reality."
            />
          </Helmet>
          <ProcessPg isMobile={isMobile} userData={userData} />
        </Route>
        <Route path={'/shop/:material/:options'}>
          <ItemPage
            isMobile={isMobile}
            userData={userData}
            cartUtils={cartUtils}
          />
        </Route>
        <Route path={'/shop/minis'}>
          <Helmet>
            <title>
              PLA Minis | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <MinisPage
            isMobile={isMobile}
            userData={userData}
            cartUtils={cartUtils}
          />
        </Route>
        <Route path={'/shop/bulk'}>
          <Helmet>
            <title>
              PLA Bulk Pack | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <BulkPage
            isMobile={isMobile}
            userData={userData}
            cartUtils={cartUtils}
          />
        </Route>
        <Route path={'/shop/:category'}>
          <ShopPage userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/shop'}>
          <Helmet>
            <link rel="canonical" href="https://www.printbed.com/shop/" />
          </Helmet>
          <ShopPage userData={userData} isMobile={isMobile} />
        </Route>
        
        <Route path={'/print-on-demand/'}>
          <Helmet>
            <title>
              Print on Demand| High-Quality 3D Printing | PrintBed
            </title>
          </Helmet>
          <PodPage
            isMobile={isMobile}
            userData={userData}
            cartUtils={cartUtils}
          />
        </Route>
        <Route path={'/item/bulk'}>
          <Redirect to={'/shop/bulk'} />
        </Route>
        <Route path={'/item/minis'}>
          <Redirect to={'/shop/minis'} />
        </Route>
        <Route path={'/item/:material/:options'} render={({match}) => (
          <Redirect to={`/shop/${match.params.material}/${match.params.options}`} />
        )} />
        <Route path={'/item/:material'}>
          <ItemPage
            isMobile={isMobile}
            userData={userData}
            cartUtils={cartUtils}
          />
        </Route>
        <Route path={'/home'}>
          <Redirect to={'/'} />
        </Route>
        <Route path={'/vending/:orderId'}>
          <Helmet>
            <title>
              Vending Machine | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <Vending userData={userData} />
        </Route>
        <Route path={'/vending'}>
          <Helmet>
            <title>
              Vending Machine | High-Quality 3D Printing Filament | PrintBed
            </title>
          </Helmet>
          <Vending userData={userData} isMobile={isMobile} />
        </Route>
        <Route path={'/'} exact>
          <Helmet>
            <title>3D Filament | $10 Overnight US Shipping | PrintBed</title>
            <link rel="canonical" href="https://www.printbed.com" />
            <meta
              name="description"
              content="Find high-quality 3D printer filament made in the USA with PrintBed. Enjoy consistent colors and perfectly wrapped spools so you can bring your vision to life."
            />
            <script type="application/ld+json">
              {structuredData()}
            </script>
          </Helmet>
          <Home isMobile={isMobile} userData={userData} />
        </Route>
        {isMobile ? (
          <Route
            path={['*/reset-password', 'reset-password']}
            component={LoginMobileModal}
          />
        ) : (
          <Route
            path={['*/reset-password', 'reset-password']}
            component={LoginModal}
          />
        )}
        <Route path={'*'} component={() => <Drip404 userData={userData} />} />
      </Switch>
      {isMobile ? (
        <Route path={['*/modal', 'modal']} component={LoginMobileModal} />
      ) : (
        <Route path={['*/modal', 'modal']} component={LoginModal} />
      )}
      {isMobile ? (
        <FooterMobile userData={userData} />
      ) : (
        <Footer userData={userData} />
      )}
      {process.env.NODE_ENV === 'production' && (
        <GoogleAnalytics userData={userData} />
      )}
      <Snack {...props} />
    </Router>
  );
}

export default Routes;
